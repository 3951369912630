















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Form from '@/shared/components/form/Form.vue'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import EditorField from '@/shared/classes/form/fields/editor-field'
import HseRoutineTemplate from '@/shared/modules/hse-routine-templates/hse-routine-templates.model'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import { getNodeApiUrlByVersion } from '@/config'
import { validateFields } from "@/shared/helpers/validate";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";

@Component({
  components: { PageHeader, Form },
  methods: { __ },
})
export default class RoutineTemplateEdit extends Vue {
  @Prop() meta!: any
  form: FormBase = null!
  loading: boolean = false

  created() {
    this.form = new FormBase()
      .setUuid(this.meta.hseRoutineTemplate.uuid)
      .setModel(HseRoutineTemplate)
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-routine-templates`)
      .setTranslatable(true)
      .setMethod(HttpMethod.PATCH)
      .setFields([
        new SearchableField()
          .setKey('hseRoutineCategoryUuid')
          .setEntryKey('hseRoutineCategoryUuid')
          .isRequired()
          .setTitle(__('admin.components.routine-templates.form.category'))
          .loadItems({ endpoint: `${getNodeApiUrlByVersion()}/hse-routine-categories`, value: 'uuid', title: 'name', perPage: 20 })
          .setSize(FieldSizes.half),
        new SelectField()
          .setKey('isVisible')
          .setItems([
            new SelectItem()
              .setValue(true)
              .setTitle(__('admin.components.routine-templates.form.visible-option')),
            new SelectItem()
              .setValue(false)
              .setTitle(__('admin.components.routine-templates.form.invisible-option')),
          ])
          .setSize(FieldSizes.half)
          .setTitle(__('admin.components.routine-templates.form.visibility')),
        new Field()
          .setKey('name')
          .isRequired()
          .setTranslatable(true)
          .setSize(FieldSizes.half)
          .setTitle(__('admin.components.routine-templates.form.routine-title')),
        new EditorField()
          .setKey('content')
          .setTranslatable(true)
          .setTitle(__('admin.components.routine-templates.form.content'))
          .isRequired(),
      ])
      .setInitialValues({
        isVisible: true,
      })
      .setValidate(true, { onlyRegionLangRequired: true })
      .setShowDefaultValidationError(true)
      .setOnSuccess(this.meta.onSuccess)
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
