






















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import HseRoutineTemplate from '@/shared/modules/hse-routine-templates/hse-routine-templates.model'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import HseRoutineTemplatesCreate from '@/admin/views/hse/routine-templates/RoutineTemplatesCreate.vue'
import HseRoutineTemplateEdit from '@/admin/views/hse/routine-templates/RoutineTemplateEdit.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import HseRoutineTemplateService from '@/shared/modules/hse-routine-templates/hse-routine-templates.service'
import { AdminRoutes } from '@/shared/router/admin'
import { getNodeApiUrlByVersion } from '@/config'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ },
})
export default class CompaniesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(HseRoutineTemplate)
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-routine-templates`)
      .setGroupBy('hseRoutineCategoryName')
      .setGroupDesc(false)
      .setPaginationNotVisible(true)
      .setDoubleClick(this.openEditDialog)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setText(__('admin.views.hse.routine-templates.index.table.columns.name')),
        new DataTableHeader()
          .setKey('is_visible')
          .setSortable(false)
          .setValueParser((value: any, item: HseRoutineTemplate) => {
            if (item.isVisible) {
              return __('admin.views.hse.routine-templates.index.table.columns.visible-option')
            }

            return __('admin.views.hse.routine-templates.index.table.columns.invisible-option')
          })
          .setText(__('admin.views.hse.routine-templates.index.table.columns.is-visible')),
      ])
      .setActions([
        new EditAction().setAction(this.openEditDialog),
        new DeleteAction().setAction(this.deleteTemplate),
      ])
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: HseRoutineTemplatesCreate,
      maxWidth: 1200,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.views.hse.routine-templates.create.form.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(hseRoutineTemplate: HseRoutineTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: HseRoutineTemplateEdit,
      maxWidth: 1200,
      persistent: true,
      meta: {
        hseRoutineTemplate,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.routine-templates.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  deleteTemplate(hseRoutineTemplate: HseRoutineTemplate): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('admin.views.hse.routine-templates.index.table.actions.delete.title'),
        text: __('admin.views.hse.routine-templates.index.table.actions.delete.text', { name: hseRoutineTemplate.name }),
        onYes: () => HseRoutineTemplateService.destroy(hseRoutineTemplate.uuid)
          .then(() => {
            this.tableRef.refresh()
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('admin.views.hse.routine-templates.index.table.actions.delete.on-success'),
            })
          }),
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
